#layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .main-content {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
  }
}
