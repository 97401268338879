h1 {
  font-family: 'TS Block';
  font-weight: 700;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: 'Overpass';
  font-weight: 700;
}

h1 {
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
}
h2 {
  font-size: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
  }
}
h3 {
  font-size: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}
h4 {
  font-size: 1rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

@font-face {
  font-family: 'TS Block';
  font-weight: 700;
  src: local('TS-Block-Bold'),
    url(../assets/fonts/TS-Block-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 400;
  src: local('DarkerGrotesque-Regular'),
    url(../assets/fonts/DarkerGrotesque-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 600;
  src: local('DarkerGrotesque-Medium'),
    url(../assets/fonts/DarkerGrotesque-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 700;
  src: local('DarkerGrotesque-Bold'),
    url(../assets/fonts/DarkerGrotesque-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 700;
  src: local('Overpass-Bold'),
    url(../assets/fonts/Overpass-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Overpass';
  font-weight: 800;
  src: local('Overpass-ExtraBold'),
    url(../assets/fonts/Overpass-ExtraBold.ttf) format('truetype');
}
