.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  .header-container {
    width: 1240px;
    max-width: 100vw;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .logo {
      img {
        cursor: pointer;
      }
    }

    .menu-icon {
      position: absolute;
      right: 16px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (min-width: 1200px) {
        display: none;
      }
    }

    .menu {
      position: absolute;
      top: 100%;
      width: 100vw;
      background-color: $black;
      padding: 30px;
      z-index: 30;

      .menu-item {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.125em;
        font-family: TS Block, sans-serif;
        color: $white;
      }
    }

    .content {
      margin-left: 100px;
      display: flex;
      align-items: center;
      @media (max-width: 1200px) {
        display: none;
      }

      .links {
        display: flex;
        align-items: center;
        gap: 60px;

        .link {
          cursor: pointer;
          font-size: 1.125em;
          font-weight: 600;
          font-family: DarkerGrotesque, sans-serif;
          color: $white;
          display: flex;
          align-items: center;
          position: relative;

          &.dropdown-toggle {
            > span {
              display: flex;
              align-items: center;
            }
          }

          .link-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            border-radius: 8px;
            border: 0;
            overflow: hidden;
            z-index: 20;
            margin-top: 8px;

            .link-dropdown-item {
              padding: 12px 32px;
              background: $dark;
              transition: ease all 0.3s;

              &:hover {
                background: $light-purple;
              }
            }
          }
        }
      }

      .social-dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: white;
        margin-left: 36px;
        margin-right: 26px;
      }

      .social-logos {
        display: flex;
        align-items: center;
        gap: 25px;
        // margin-right: 50px;

        img {
          cursor: pointer;
        }
      }
    }
  }
}
