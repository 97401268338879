html,
body {
  padding: 0;
  margin: 0;
  font-family: 'DarkerGrotesque', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-size: 24px;
  background-color: $background;
  color: white;
}

$col-width: minmax(0, 1fr);

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;

  &.inline-link {
    text-decoration: underline;
    color: $very-light-purple;
    font-weight: 700;
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* Scrollbar css */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #0e0329;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c3e4d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a2b35;
}

.cursor-pointer {
  cursor: pointer;
}

.text-btn,
.tab-btn {
  font-family: 'TS Block';
  line-height: 1.5em;
  padding: 16px 20px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s;

  &[aria-disabled='true'] {
    cursor: default;
    opacity: 0.6;
  }
}

.primary-btn {
  padding: 12px 32px;
  border-radius: 100px;
  border: none;
  background-color: $purple;
  font-size: 16px;
  font-weight: bold;
  color: $white;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.3s;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &:not(:disabled):hover {
    background-color: $pink;
  }
}

.secondary-btn {
  padding: 8px 16px;
  border-radius: 40px;
  border: 2px solid $light-gray;
  background-color: transparent;
  font-size: 16px;
  color: $light-gray;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.3s;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &:not(:disabled):hover {
    background-color: $light-gray;
    color: $dark-blue;
  }

  // variants
  &.with-icon {
    gap: 0;
    padding-left: 24px;
  }

  &.square {
    border-radius: 0;
  }

  &.big {
    padding: 16px 40px;
    border-radius: 16px;
  }
}

.black-btn {
  font-size: 16px;
  font-weight: 600;
  width: 250px;
  border: none;
  color: white;
  padding: 16px 40px;
  background: #000000;
  box-shadow: 5px 10px 30px rgba(134, 0, 237, 0.4);
  border-radius: 16px;
  cursor: pointer;
}

.text-btn {
  color: $light-purple;

  &.red {
    color: $red;
    opacity: 0.7;
  }

  &:not([aria-disabled='true']):hover {
    text-decoration: underline;
  }
}

.divider {
  width: 100%;
  max-width: 1240px; // same as .container
  height: 1px;
  background-color: $light-gray;
}
