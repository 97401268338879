.connect-wallet-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 16px !important;
}

.header-wallet-btn {
  width: 200px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.connect-wallet-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 8px;
  }

  button {
    font-weight: 600;
    border: none;
  }
}

.connect-wallet-modal-content {
  width: 600px;
  max-width: 90vw;
  background-color: #251e41;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

//

.connect-wallet-btn {
  padding: 12px 16px;
  border-radius: 8px;
  border: 0;
  background-color: #8600ed;
  color: white;
  cursor: pointer;
  transition: all ease 0.3s;
}

.connect-wallet-btn:disabled {
  cursor: default;
  opacity: 0.6;
}

.connect-wallet-btn:not(:disabled):hover {
  background-color: #be51fd;
}
