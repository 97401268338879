.footer {
  width: 100%;
  background-color: $black;
  display: flex;
  justify-content: center;
  font-size: 16px;

  .footer-container {
    width: 1240px;
    max-width: 100%;
    padding: 120px 16px 150px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-direction: column;
    }

    .footer-text {
      color: gray;
      font-size: 0.875em;
      margin-top: 16px;
    }

    .footer-links-socials {
      display: flex;
      gap: 80px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
        margin-top: 32px;
      }

      .footer-links {
        display: flex;
        gap: 72px;
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 0px;
          margin-bottom: 32px;
        }

        .footer-links-item {
          color: $white;
          margin-bottom: 25px;
          cursor: pointer;
        }
      }

      .footer-socials {
        display: flex;
        align-items: flex-start;
        gap: 32px;

        img {
          cursor: pointer;
        }
      }
    }
  }
}
